<!--
 * @Author: your name
 * @Date: 2022-03-10 16:31:41
 * @LastEditTime: 2022-06-14 17:07:08
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\components\confirmbox\index.vue
-->
<template>
  <div>
    <van-overlay :show="show">
      <div class="realbox" @click.stop>
        <div class="title">{{info.title}}</div>
        <div class="content">{{info.content}}<span class="span">{{info.span}}</span></div>
        <div class="fotter">{{info.fotter}}</div>
        <div class="button">
          <div class="left" @click="closebtn">{{info.left}}</div>
          <div class="line"></div>
          <div class="right" @click="determine">{{info.right}}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
  props:{
    show:{
      typeof:Boolean,
      default:false
    },
    info:{
      typeof:Object,
      default:() =>{
        return {
          title:"当前卡未实名认证",
          content:"根据工信部《入网用户真实身份信息登记规定》,您当前使用的流量卡或设备",
          span:"需实名认证才正常使用。",
          fotter:"实名认证开放时间: 每日8:00-21:00",
          left:"取消",
          right:"立即认证"
        }
      }
    }
  },
  methods:{
    closebtn(){
      this.$emit("closebtn")
    },
    determine(){
      this.$emit("determine")
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-overlay{
  z-index: 9;
  .realbox{
  width: 70%;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  // align-items: center;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  .title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 25px;
  }
  .content{
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 20px;
    line-height: 1.5;
    margin-bottom: 5px;
    .span{
      color: #4883ab;
    }
  }
  .fotter{
    color: #d48b2c;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .button{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    .left{
      width: 50%;
      text-align: center;
      line-height: 50px;
    }
    .right{
      width: 50%;
      color: #3285eb;
      text-align: center;
      line-height: 50px;
    }
    .line{
      width: 1px;
      height: 30px;
      background-color: #ddd;
      margin-top: 10px;
    }
  }
}
}
</style>