<!--
 * @Author: your name
 * @Date: 2022-04-09 11:24:36
 * @LastEditTime: 2022-04-22 15:04:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\qrcode\index.vue
-->
<template>
  <div>
    <van-overlay :show="showqrcode" @click="clkqrcode">
        <div class="qrcode" @click.stop>
          <slot>
          <p class="text">享受更多服务</p>
          <p class="texttwo">请长按图片识别并关注二维码</p>
          <img :src="url" alt="" class="img">
          </slot>
        </div>
      
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props:{
    showqrcode:{
      type:Boolean,
    },
    url:{
      type:String,
      default:require("@/assets/images/guide/qrcode.jpg")
    }
  },
  methods:{
    clkqrcode(){
      this.$emit("clkqrcode",false)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-overlay{
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 4;
  .qrcode{
    width: 70%;
    // margin: 220px auto 0;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px;
    .text{
      text-align: center;
      font-size: 14px;
      padding-top: 20px;
    }
    .texttwo{
      text-align: center;
      font-size: 14px;
      margin: 10px auto 10px;;
    }
    .img{
      display: block;
      width: 200px;
      height: 200px;
      margin: 0 auto 20px;
    }
  }
}
</style>