<!--
 * @Author: your name
 * @Date: 2022-04-25 15:04:33
 * @LastEditTime: 2022-06-07 08:58:26
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\newswifi\index.vue
-->
<template>
  <div>
    <van-popup position="top" v-model="show" :close-on-click-overlay="false" @click-overlay="clkOverlay">
      <van-radio-group v-model="radio" @change="change">
        <van-radio :name="item.iccid" v-for="item in list" :key="item.iccid" checked-color="#fffc00" :class="{select:radio == item.iccid}" :disabled=isdisabled>
          <div class="top">
            <div class="left" :class="{selectleft:radio == item.iccid}">{{item.alias_name}}</div>
            <img src="@/assets/images/index/status.png" alt="" class="right" v-if="item.icstatus == '00'">
            <img src="@/assets/images/index/abnormal.png" alt="" class="right" v-else>
          </div>
          <div class="iccid" :class="{selecticcid:radio == item.iccid}">id:{{item.iccid}}</div>
          <div class="money" :class="{selmoney:radio == item.iccid}">
            <div class="money-icon">￥</div><div class="money-text">{{item.front}}</div><div class="money-icon">{{item.after}}</div><div class="money-title" :class="{seltitle:radio == item.iccid}">(余额)</div>
          </div>
          <div class="bottom">
            <div class="bleft" :class="{noOpen:item.buy_id == 0 && radio != item.iccid,selectblN:radio == item.iccid && item.buy_id == 0,selectopen:item.buy_id > 0 && radio == item.iccid}">套餐{{item.buy_id == 0?"未":"已"}}开通</div>
            <div class="line"></div>
            <div class="bright" :class="{noOpen:item.ispick == 1  && radio != item.iccid,selectblN:radio == item.iccid && item.ispick == 1,selectopen:item.ispick == 2 && radio == item.iccid}">{{item.ispick == 1?"未":"已"}}实名</div>
          </div>
        </van-radio>
      </van-radio-group>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return{
      radio:"1",  // 默认选择的radio
      list:[],  // wifi列表
      isdisabled:false,  // 是否禁用选择
    }
  },
  props:{
    // 控制弹出层
    show:{
      type:Boolean,
      default:false
    },
    // 设备的所有信息
    cardinfo:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    // 设备被更改过wifi别名之后
    setName:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    'cardinfo.phone':{
      handler(newvalue,olvalue){
        if(newvalue != "" && newvalue && this.list.length == 0){
          this.getList()
          this.radio = sessionStorage.getItem("iccid")
        }
      },
      deep:true,
      immediate:true
    },
    setName:{
      handler(newvalue,oldvalue){
        if(newvalue){
          this.getList()
          this.$emit("overGetlist")
        }
      }
    },
    $route:{
      handler(newvalue,oldvalue){
        if(newvalue.fullPath == "/user"){
          this.isdisabled = true
        }else{
          this.isdisabled = false
        }
      },
      deep:true,
      immediate:true
    }
  },
  mounted(){
    this.radio = sessionStorage.getItem("iccid")
    let phone = window.sessionStorage.getItem("phone")
    if(phone){
    //   this.$nextTick(()=>{
      this.getList()
    //   })
    }
  },
  methods:{
    // 点击遮罩层触发
    clkOverlay(){
      this.$emit("clkOverlay")
    },
    // 选中的值发生改变
    change(name){
      sessionStorage.setItem("iccid",name)
      this.$emit("change")
    },
    // 获取当前页面的信息
   getList(){
      let data ={
        phone:sessionStorage.getItem("phone")
      }
    this.iccidList({data}).then(res =>{
        if(res.data.code == 1){
          this.list = res.data.list
          this.list.forEach(item =>{
            let str = item.c_balance + ""
            let num = str.indexOf(".")
            if(num != -1){
              item.after = str.substring(num,str.length)
              item.front = str.substring(0,num)
            }
          })
        }else{
          this.$toast(`${res.data.msg}`)
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.van-popup{
  border-radius: 0 0 10px 10px;
  padding-bottom: 44px;
  background-color: #f6f9fb;
  .van-radio-group{
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  .select{
    background-image: url("~@/assets/images/newswifi/open.png") !important; 
  }
  .van-radio{
      flex-basis: 48%;
      min-height: 100px;
      background-image: url("~@/assets/images/newswifi/close.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 8px 0;
      display: block;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      .van-radio__icon--round{
        position: absolute;
        right: 9px;
        bottom: 10px;
      }
       .van-radio__icon--checked .van-icon{
          color: #004bc9;
      }
      .van-radio__label{
        width: 85%;
        display: block;
        margin:  15px auto 0;
        line-height: 0;
        .top{
        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
           .selectleft{
             color: #fff !important;
           }
          .left{
            color: #444444 ;
            font-size: 14px;
            font-weight: 600;
            max-width: 110px;
            height: 14px;
            text-align: center;
            line-height: 14px;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
          }
          .right{
            width: 24px;
            height: 12px;
            display: block;
          }
        }
        .selecticcid{
         color:#cee0ff !important;
        }
        .iccid{
          color:#a0a6af;
          font-size: 12px;
          margin-top: 14px;
          margin-bottom: 10px;
        }
        .selmoney{
          color:#cee0ff !important;
        }
        .money{
          display: flex;
          height: 18px;
          color: #afbed8;
          line-height: 18px;
          margin:6px 0  2px 0;
          .money-icon{
            font-size: 12px;
            margin-top: 2px;
          }
          .money-text{
            font-size: 18px;
          }
          .seltitle{
            color: #6ea4ff !important;
          }
          .money-title{
            font-size: 12px;
            transform: scale(0.8);
            color: #dce1e9;
            margin-left: 2px;
            font-weight: 500;
            margin-top: 2px;
          }
        }
        .bottom{
          display: flex;
          transform-origin: left;
          transform: scale(0.8);
          color: #afbed8;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          line-height: 100%;
          .noOpen{
            color: #e73737;
          }
          .selectblN{
            color: #fffc00 !important;
          }
          .selectopen{
            color: #fff !important;
          }
          .bleft{
            margin-top: 6px;
          }
          .line{
            height: 14px;
            width: 2px;
            margin: 5px 10px 0;
            background-image: url("~@/assets/images/newswifi/line.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .noname{
            color: #e73737;
          }
          .bright{
            margin-top: 6px;
          }
        }
      }
    }
  }
}

</style>