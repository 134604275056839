<!--
 * @Author: your name
 * @Date: 2022-04-14 15:10:32
 * @LastEditTime: 2022-04-25 09:45:08
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \锐WIFI新\src\views\guide\components\timeToast\index.vue
-->
<template>
  <div>
    <van-overlay :show="showToast" @click.stop>
        <div class="toast">
          <img src="@/assets/images/guide/showtoast.png" alt="" class="img">
          <div class="button" @click="clkClose">好的，我知道了</div>
        </div>
  </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  props:{
    showToast:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    clkClose(){
      this.$emit("clkClose",false)
    }
  }
}
</script> 
<style lang="less" scoped>
/deep/.van-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center ;
  .toast{
 width: 280px;
 margin: 0 auto;
 position: relative;
 .img{
   width: 100%;
   display: block;
 }
 .button{
   width: 170px;
   height: 40px;
   position: absolute;
   left: 50%;
   transform: translate(-50%,-100%);
   text-align: center;
   line-height: 40px;
   background-color: #fff;
   font-size: 14px;
   color: #2867f6;
   border-radius: 20px;
   font-weight: 400;
 }
}
}
</style>